import React from 'react';
import styled from 'styled-components';
import {Text, Heading} from '../../Widgets';
import Image from '../../Components/Image';
import {dateUtil} from '../../Utils/';
const config = require('../../data.json');

const statuses = {
  waiting: {
    icon: '/images/order-icon-payment-waiting.svg',
    title: '已產生月結繳費單，待繳費',
    subtitle: '已產生月結繳費單，請於繳款截止日前完成繳費。',
  },
  remitted: {
    icon: '/images/order-icon-refunding.svg',
    title: '待對帳',
    subtitle: '已填寫匯款資訊，請聯絡客服進行款項確認。',
  },
  success: {
    icon: '/images/order-icon-completed.svg',
    title: '已繳費完成',
    subtitle: '已繳費完成',
  },
  expired: {
    icon: '/images/order-icon-failure.svg',
    title: '已過期',
    subtitle:
      '月結單繳費期限已到期，為避免因凍結會員下單權限而損失您的權益，請儘速前往繳費！',
  },
};

export default function TopSection(props) {
  const {record} = props;

  if (!record) {
    return null;
  }

  const status = statuses[record.payment_status];

  return (
    <Wrapper>
      <div className="id-section">
        <Text size="lg" weight="500">
          月結對帳單 # {record.id}
        </Text>
        <Text size="xs">{`月結單結算區間: ${record.period_from} ～ ${record.period_to}`}</Text>
      </div>
      <div className="divider" />
      <div className="status-section">
        <Image
          src={status?.icon}
          style={{width: 80, height: 80}}
          alt="order state image"
        />
        <div>
          <Text size="lg" color={config.colors.main} weight="500">
            {status?.title}
          </Text>
          <Text size="xs">{status?.subtitle}</Text>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 15px;
  border: solid 1px ${config.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;

  & > .id-section {
    flex-basis: 300px;
    align-self: center;
  }

  & > .status-section {
    flex: 1;
    display: flex;
    align-items: center;
  }

  & > .divider {
    background-color: ${config.colors.border};
    flex-basis: 1px;
    margin: 0 15px;
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    flex-direction: column;

    & > .divider {
      margin: 15px 0;
    }
  }
`;
