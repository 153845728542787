import React, {Fragment} from 'react';
import {OrderBlock, Row, Text, Button} from '../../Widgets';
import {
  LOVECODE_OPTIONS,
  INVOICE_TYPES,
  TWO_COPIES_TYPES,
} from '../../dictionary';
import {transfromFromInvoice} from '../../hooks/use-invoice-data';
const config = require('../../data.json');

const downloadUrl = 'https://inv.ezpay.com.tw/Invoice_index/search_platform';

export default function InvoiceSection({invoices}) {
  if (invoices.length === 0) {
    return (
      <OrderBlock title="發票資訊">
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            開立狀態：
          </Text>
          <Text size="sm">發票尚未開立</Text>
        </Row>
      </OrderBlock>
    );
  } else {
    const first = invoices[0];

    const invoice = transfromFromInvoice(first);

    const type = INVOICE_TYPES[invoice.invoice_type];
    const subtype = TWO_COPIES_TYPES[invoice.invoice_subtype];
    return (
      <OrderBlock title="發票資訊">
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            發票種類：
          </Text>
          <Text size="sm">{type.label}</Text>
        </Row>
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            開立狀態：
          </Text>
          <Text size="sm">發票已開立</Text>
        </Row>

        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            發票號碼：
          </Text>
          <Text size="sm" color={config.colors.main}>
            {invoice.invoice_number}
          </Text>
        </Row>

        {type.value === 'donate' ? (
          <div>
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                愛心碼：
              </Text>
              <Text size="sm">{invoice.love_code}</Text>
            </Row>
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                捐贈單位名稱：
              </Text>
              <Text size="sm">
                {LOVECODE_OPTIONS.find((x) => x.value === invoice.love_code)
                  ?.label || '-'}
              </Text>
            </Row>
          </div>
        ) : type.value === 'two_copies' && subtype ? (
          <div>
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                載具類別：
              </Text>
              <Text size="sm">{subtype.label}</Text>
            </Row>

            {invoice.invoice_subtype !== 'ezpay_vehicle' && (
              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  {subtype.label}
                  {'：'}
                </Text>
                <Text size="sm" color={config.colors.main}>
                  {invoice.carrier_num}
                </Text>
              </Row>
            )}
          </div>
        ) : type.value === 'three_copies' ? (
          <div>
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                公司統編：
              </Text>
              <Text size="sm">{invoice.gui_number}</Text>
            </Row>
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                公司抬頭：
              </Text>
              <Text size="sm">{invoice.company_title}</Text>
            </Row>
          </div>
        ) : null}

        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            發票日期：
          </Text>
          <Text size="sm">{invoice.create_time}</Text>
        </Row>
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            隨機碼：
          </Text>
          <Text size="sm">{invoice.random_num}</Text>
        </Row>
        <Button href={downloadUrl}>下載發票證明聯</Button>
      </OrderBlock>
    );
  }
}
