import React from 'react';
import {OrderBlock, Row, Text} from '../../Widgets';
import MonthlyPdfDownload from '../../Components/MonthlyPdfDownload';
const appConfig = require('../../data.json');

export default function Calculation(props) {
  const {record, orders, invoice} = props;

  return (
    <OrderBlock title="月結總額">
      <Row>
        <Text size="sm" style={{flex: 1}}>
          訂單總計金額（含稅）
        </Text>
        <Text
          weight="600"
          color={appConfig.colors.error}
          style={{fontSize: 24}}>
          $ {record.amount}
        </Text>
      </Row>

      <MonthlyPdfDownload monthly={record} orders={orders} invoice={invoice} />
    </OrderBlock>
  );
}
