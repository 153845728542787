import React from 'react';
import {OrderBlock, Row, Text} from '../../Widgets';
import {Context} from '../../AppContext';
import {MONTHLY_TYPE} from '../../dictionary';
const appConfig = require('../../data.json');

export default function MonthlyOrderInfoSection({record}) {
  const app = React.useContext(Context);
  const {profile} = app.state;

  if (!record) {
    return null;
  }

  return (
    <OrderBlock title="月結單內容">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          結算區間：
        </Text>
        <Text size="sm">
          {record.period_from} ～ {record.period_to}
        </Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          當期消費總金額：
        </Text>
        <Text size="sm">{record.amount} 元</Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          月結額度：
        </Text>
        <Text size="sm">{profile?.monthly_quota} 元</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          月結結算日：
        </Text>
        <Text size="sm">{MONTHLY_TYPE[profile?.monthly_type] || '--'} </Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          月結繳費緩衝期：
        </Text>
        <Text size="sm">{profile?.monthly_pay_buffer} 天</Text>
      </Row>
    </OrderBlock>
  );
}
