import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Heading, Text} from '../../Widgets';
import TopSection from './TopSection';
import MonthlyOrderInfoSection from './MonthlyOrderInfoSection';
import Calculation from './MonthlyCalculation';
import ORDER_TABLE_TYPES from '../../Components/OrderTable/orderTableTypes';
import PaymentSection from './PaymentSection';
import InvoiceSection from './InvoiceSection';
import LargeScreenTable from '../../Components/OrderTable/LargeScreenTable';
import SmallScreenTable from '../../Components/OrderTable/SmallScreenTable';
import useDimensions from '../../hooks/use-dimension';
import useOrderTable from '../../hooks/use-order-table';
const queryString = require('query-string');
const appConfig = require('../../data.json');

const dbg = {
  id: '202101250311097264', //"202101270632376822"
};

export default function MonthlyDetail(props) {
  const {location} = props;
  const {id = dbg.id, ...query} = queryString.parse(location.search); //monthly_order.id
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const app = useContext(Context);
  const {profile} = app.state;

  const {dimension} = useDimensions();

  const {orders, columns, filters, total} = useOrderTable({
    pathname: props.location.pathname,
    query,
    defaultType: ORDER_TABLE_TYPES.MONTHLY,
    defaultMonthly: id,
  });

  const getRecord = useCallback(async () => {
    setLoading(true);
    if (id && profile) {
      try {
        let resp = await app.actions.getMonthlyOrder(id);
        setRecord(resp);
      } catch (ex) {
        console.warn(ex);
      }
    }
    setLoading(false);
  }, [id, profile, app.actions]);

  // const getOrders = useCallback(async () => {
  //   try {
  //     let resp = await app.actions.getOrders({
  //       payment_type: 'monthly',
  //       no_page: true,
  //       monthly_order: id,
  //     });
  //     setAllOrders(resp);
  //   } catch (ex) {
  //     console.warn(ex);
  //   }
  // }, [id]);

  const getInvoices = useCallback(async () => {
    try {
      let resp = await app.actions.getInvoices({
        monthly_order: id,
      });
      setInvoices(resp.results);
    } catch (err) {
      console.warn(err);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      await getRecord();
      app.actions.setLoading(false);
    })();
  }, [getRecord]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  if (loading) {
    return null;
  }

  if (!profile) {
    return <UnAuthResult />;
  }

  if (!record) {
    return (
      <ContentWrapper>
        <Heading align="center" color={appConfig.colors.main}>
          無此月結單
        </Heading>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <TopSection record={record} />

      <div>
        {dimension.innerWidth > appConfig.breakpoints.sm ? (
          <LargeScreenTable
            loading={loading}
            orders={orders}
            columns={columns}
            filters={filters}
            setFilters={() => {}}
            total={total}
            noPage={true}
          />
        ) : (
          <SmallScreenTable
            loading={loading}
            orders={orders}
            columns={columns}
            filters={filters}
            setFilters={() => {}}
            total={total}
            noPage={true}
          />
        )}
      </div>

      <OrderTableFooter amount={record.amount} count={orders.length} />

      <div style={{height: 20}} />

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={16} lg={16}>
          {/* 付款資訊 */}
          <PaymentSection record={record} onUpdate={getRecord} />

          {/* 月結資訊 */}
          <MonthlyOrderInfoSection record={record} />

          {/* 發票資訊 */}
          <InvoiceSection invoices={invoices} />

          {/* 防詐騙宣導 */}

          <div style={{marginBottom: 30}}>
            提醒您目前常見之詐騙手法如下： 1.
            只要來電顯示開頭「＋」者，都是國際電話！來電顯示開頭「＋」者，是國際電話！「+886」是出自台灣的國際電話，小心辨認以防受騙！
            2.
            國內ATM自動提款機並沒有分期付款設定解除等服務，只要提及「ATM解除分期付款設定」，就一定是詐電話，切勿依來電指示操作自動提款機提至銀行提（匯）款或交付現金給任何人，以免被騙。
            3.{appConfig.information.name}
            網路平台無貨到付款服務，收取包裹前請確認查詢寄件人或公司等訂單資料，再判斷是否要領取包裹，以免受騙!瞭解更多反詐騙詳細內容請至「內政部警政署」165反詐騙網站。
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Calculation
            record={record}
            orders={orders}
            invoice={invoices.slice(0, 1).pop()}
          />
        </Col>
      </Row>
    </ContentWrapper>
  );
}

function OrderTableFooter({count = 0, amount}) {
  return (
    <Block
      style={{
        border: `solid 1px ${appConfig.colors.border}`,
        borderTop: 0,
        padding: 15,
      }}>
      <div className="col" style={{flex: 3}}>
        <Text size="sm">總計 {count} 筆訂單</Text>
      </div>
      <div className="col" style={{padding: 0, flex: '0 0 270px'}}>
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          <Text weight="500" size="sm" style={{marginRight: 10}}>
            當月已消費金額(含稅)
          </Text>
          <Text
            weight="500"
            size="xl"
            color={appConfig.colors.main}
            style={{lineHeight: 1.1}}>
            {amount}
          </Text>
        </div>
        <Text size="xs" color={appConfig.colors.main}>
          ＊實際繳費金額請依每個月的銷帳請款單為主。
        </Text>
      </div>
    </Block>
  );
}

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  flex: ${(props) => props.flex || 1};
  padding: 10px 15px;
  ${(props) => props.css}

  & > .col {
    display: flex;
    flex-direction: column;
    flex: ${(props) => props.flex || 1};
    color: ${(props) => props.color || '#505050'};
    padding-right: 50px;
  }
`;
