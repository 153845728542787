import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  useRef,
  useMemo,
} from 'react';
import styled from 'styled-components';
import MonthlyPdfForm from '../PdfForms/MonthlyPdfForm';
import {Context} from '../AppContext';
import {pdf} from '@react-pdf/renderer';
import {message} from 'antd';
import {saveAs} from 'file-saver';
const config = require('../data.json');

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function MonthlyPdfDownload(props) {
  const {monthly, orders, invoice} = props;
  const app = useContext(Context);
  const {profile} = app.state;

  const formRef = useMemo(
    () => (
      <MonthlyPdfForm
        orders={orders}
        monthlyOrder={monthly}
        profile={profile}
        invoice={invoice}
      />
    ),
    [monthly, orders],
  );

  const generate = useCallback(async () => {
    app.actions.setLoading(true);

    const blob = await pdf(formRef).toBlob();
    app.actions.setLoading(false);

    saveAs(blob, '月結繳費單.pdf');
  }, [app.actions, formRef]);

  if (!monthly) {
    return null;
  }

  return (
    <>
      <Button
        onClick={(e) => {
          generate();
        }}>
        下載月結繳費單
      </Button>
    </>
  );
}

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  color: ${config.colors.text};
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
  padding: 8px 0;
  box-shadow: 0 2px 5px -2px #999;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.75;
  }

  ${(props) => props.css}
`;
